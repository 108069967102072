<ng-container *ngIf="!loginDisplay; else isLogin">
  <ic-not-auth></ic-not-auth>
</ng-container>

<ng-template #isLogin>
  <ic-header></ic-header>

  <div class="min-h-full">
    <main class="container mx-auto my-8">
      <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
      <router-outlet></router-outlet>
    </main>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoaderAndUtilsService } from '@core/services/loaderAndUitils.service';
import { ErrorBackService } from '@core/services/error-back.service';
import { StoreService } from '@auth/store.service';
import { AuthService } from '@auth/auth.service';

import { catchError, finalize, switchMap } from 'rxjs/operators';
@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  private countRequest = 0;

  constructor(
    private loaderService: LoaderAndUtilsService,
    private errorBackService: ErrorBackService,
    private _authService: AuthService,
    private storeService: StoreService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //Agregamos los header y params para cada  request
    let authReq = this.addParamsHeader(req);

    if (!this.countRequest) {
      this.loaderService.isLoading.next(true);
    }
    this.countRequest++;

    //Si la respuesta no es exitosa se reintenta 3 veces
    return next.handle(authReq).pipe(
      switchMap((event: HttpEvent<any>) => {
        return this.handleResponse(event, authReq, next);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          this.errorBackService.handleError(error);
        }
        return throwError(() => error);
      }),
      finalize(() => {
        this.countRequest--;
        if (!this.countRequest) {
          this.loaderService.isLoading.next(false);
        }
      })
    );
  }

  private handleResponse(
    event: HttpEvent<any>,
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (event instanceof HttpResponse) {
      if (event?.body?.code === 498) {
        return this._authService.refreshToken().pipe(
          switchMap(() => {
            request = this.addParamsHeader(request);
            return next.handle(request);
          })
        );
      }
    }
    return of(event);
  }

  private addParamsHeader(authReq: HttpRequest<any>): HttpRequest<any> {
    if (authReq.url.includes('caranty')) {
      let optionsRequest = {};

      const headerCase = {
        'Content-type': 'application/json',
        'api-key': `${environment.OcpApimKey}`,
        'x-functions-key': '',
      };

      const urlObject = new URL(authReq.url);

      const urlParams = urlObject.searchParams.get('backend');

      if (urlParams === 'azure') {
        headerCase['x-functions-key'] = environment.MSKeyCRM;

        optionsRequest = {
          setHeaders: { ...headerCase },
          setParams: { code: environment.MSKeyCRM },
        };
      } else if (urlParams === 'azure-crm') {
        console.log('REQUEST: azure-crm');
        headerCase['x-functions-key'] = environment.MSKeyGeneral;

        optionsRequest = { setHeaders: { ...headerCase } };
        authReq = authReq.clone(optionsRequest);

        authReq = this.addTokenHeader(authReq);
      } else if (urlObject.host.includes('azurewebsites.net')) {
        headerCase['x-functions-key'] = environment.MSKeyGeneral;

        optionsRequest = { setHeaders: { ...headerCase } };

        authReq = authReq.clone(optionsRequest);

        authReq = this.addTokenHeader(authReq);
      } else {
        headerCase['x-functions-key'] = environment.MSKeyCRM;
        optionsRequest = { setHeaders: { ...headerCase } };
      }

      authReq = authReq.clone(optionsRequest);
    }

    return authReq;
  }

  private addTokenHeader(request: HttpRequest<any>) {
    const token = this.storeService.getToken();

    if (token) {
      return request.clone({
        headers: request.headers.set('Authorization', token),
      });
    }
    return request;
  }
}

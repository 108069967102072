import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { NotAuthComponent } from './not-auth/not-auth.component';
import { HomeComponent } from './home.component';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { SharedModule } from '@shared/shared.module';
@NgModule({
  declarations: [HeaderComponent, NotAuthComponent],
  imports: [CommonModule, RouterModule, OverlayModule, SharedModule],
  exports: [NotAuthComponent, HeaderComponent],
})
export class HomeModule {}

import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoaderAndUtilsService } from '@core/services/loaderAndUitils.service';
import { AnimationLoader, AnimationOptions, LottieComponent, provideLottieOptions } from 'ngx-lottie';

@Component({
  selector: 'ic-loader',
  template: `<div class="container_loading" *ngIf="loading">
    <ng-lottie [styles]="styles" [options]="options"> </ng-lottie>
  </div>`,
  styleUrls: ['./loader.component.css'],
  imports: [CommonModule, LottieComponent],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  providers: [
    provideLottieOptions({
      player: () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web'),
    }),
    AnimationLoader,
  ],
})
export class LoaderComponent implements OnInit {
  loading: boolean = true;

  options: AnimationOptions = {
    path: '/assets/Loader_Cars.json',
  };

  styles: Partial<CSSStyleDeclaration> = {
    maxWidth: '500px',
    margin: '0 auto',
  };

  constructor(private loaderService: LoaderAndUtilsService) {}

  ngOnInit(): void {
    this.loaderService.isLoading.subscribe((isLoading) => {
      this.loading = isLoading;
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ProfileType } from '@core/models/utils.model';

@Injectable({
  providedIn: 'root',
})
export class GraphService {
  GRAPH_ENDPOINT = environment.msalConfig.apiConfig.graphEndpoint;

  private profileSubject = new BehaviorSubject<ProfileType>({} as ProfileType);
  public profile$ = this.profileSubject.asObservable();

  private groupsSubject = new BehaviorSubject<String[]>([]);
  public readonly groupsRol$: Observable<String[]> = this.groupsSubject.asObservable();

  constructor(protected _http: HttpClient) {}

  getDataMeProfile() {
    return this._http.get(`${this.GRAPH_ENDPOINT}/me`);
  }

  setProfileUser(value: ProfileType) {
    if (Object.values(this.profileSubject.getValue()).length === 0) this.profileSubject.next(value);
  }

  get getGroups(): Observable<String[]> {
    return this.groupsSubject.asObservable();
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root',
})
export class LoaderAndUtilsService {
  public isLoading = new BehaviorSubject(false);

  public isContainer = new BehaviorSubject(true);

  public refreshLater = new BehaviorSubject(false);

  constructor(
    protected _http: HttpClient,
    private observer: BreakpointObserver
  ) {}

  getResourcesCRM() {
    return this._http.get(`${environment.WebApiUrlCarantyamdev}/resources/crm?backend=azure`);
  }

  isMobileObserver() {
    return this.observer.observe(['(max-width: 768px)']);
  }
}

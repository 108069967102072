import { format, addHours, addDays } from 'date-fns';
import { es } from 'date-fns/locale';

let eventGuid = 0;

export function isObject(val: any) {
  if (val === null) {
    return false;
  }
  return typeof val === 'function' || typeof val === 'object';
}

export function formatDateCustom(date: string, formatString: string): string {
  try {
    return format(new Date(date), formatString, { locale: es });
  } catch (error) {
    console.log(`Invalid date: ${date}`);
    return date;
  }
}

export function addHourDateCustom(date: string, hour: number, formatReturn?: string): string {
  try {
    const operation = addHours(new Date(date), hour);
    return format(operation, formatReturn ?? "yyyy-MM-dd'T'kk:mm:ss");
  } catch (error) {
    return date;
  }
}

export function isNumeric(value: string | number): boolean {
  return /^-?\d+$/.test(value.toString());
}

export function isNumericOrDecimal(value: string | number): boolean {
  return /^[-+]?[0-9]*\.?[0-9]+$/.test(value.toString());
}

export function createEventId() {
  return String(eventGuid++);
}

export function getCurrentRangeFullCalendar(currentRange: {
  start: string | number | Date;
  end: string | number | Date;
}) {
  return {
    start: format(addDays(new Date(currentRange.start), 1), 'yyyy-MM-dd'),
    end: format(new Date(currentRange.end), 'yyyy-MM-dd'),
  };
}

export function generateRandomStrings(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const getNameFileToURL = (url: string) => {
  if (!url) return undefined;
  url = url.replace(/%2F/, '/');
  const match = /\/([^\/?#]+)\.[^\/?#]+$/i.exec(url);
  return match ? match[1] : undefined;
};

export const getExtensionFile = (filename: string | undefined) => {
  if (!filename) return undefined;
  const match = /\.([^.]+)$/.exec(filename);
  return match ? match[1] : undefined;
};

export const fileToBase64 = async (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => resolve(btoa(reader?.result?.toString() ?? ''));
    reader.onerror = reject;
  });

export const avatarName = (name: string): string => {
  const resultado = name.concat(' ').replace(/([a-zA-Z]{0,} )/g, function (match) {
    return match.trim()[0];
  });
  return resultado.substring(0, 2).toUpperCase();
};

//https://carantyamdev.azure-api.net/caranty-dev/cars/getDetailForAutosAndPublicaciones/?flag_detail=publicaciones

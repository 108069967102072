import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { StoreService } from '@auth/store.service';

@Injectable({
  providedIn: 'root',
})
export class RolUserGuard implements CanActivate {
  constructor(
    private _storeService: StoreService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const scopes = this._storeService.getRol();

    console.log(state.url);
    const findScope = scopes.find((rol: any) => state.url.includes(rol));

    return true;
    if (!findScope) {
      console.log('No tienes permisos para acceder a esta ruta.', 'Acceso denegado');
      this.router.navigate(['/404']);
      return of(false);
    }

    return of(true);
  }
}

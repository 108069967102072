import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideRouter, Route, RouterLink } from '@angular/router';

@Component({
  selector: 'app-page-notfound',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './page-notfound.component.html',
  styleUrls: ['./page-notfound.component.css'],
})
export class PageNotfoundComponent {}

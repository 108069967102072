import { Injectable } from '@angular/core';
import { ProfileType } from '@core/models/utils.model';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MenuItem, OrigenMenu } from '@core/models/roles.model';
import { environment } from 'src/environments/environment';

const TOKEN_KEY = 'token-api';
const USER_PROFILE = 'user-profile';
const USER_GROUPS = 'user-groups';
const LIST_CARANTYHOST = 'list-carantyhost';
const LIST_ALLCARANTYHOST = 'listall-carantyhost';
const LIST_SCOPES = 'list-scopes';
const RESOURCES = 'resources-crm';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  profile!: ProfileType;
  profileToken: any = {};
  public menuSubject = new BehaviorSubject<MenuItem[]>([]);
  public menu$ = this.menuSubject.asObservable();

  public listCarantyHostSubject = new BehaviorSubject<any[]>([]);
  public listCarantyHost$ = this.listCarantyHostSubject.asObservable();

  public groupsRolSubject = new BehaviorSubject<String[]>([]);
  public groupsRol$ = this.groupsRolSubject.asObservable();

  constructor(private _http: HttpClient) {}

  logoutRemove(): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(RESOURCES);
  }

  public saveToken(token: string): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, token);
  }

  public savedUserProfile(profile: ProfileType): void {
    localStorage.removeItem(USER_PROFILE);
    localStorage.setItem(USER_PROFILE, JSON.stringify(profile));
  }

  public savedGroupsUser(groups: any) {
    localStorage.removeItem(USER_GROUPS);
    localStorage.setItem(USER_GROUPS, JSON.stringify(groups));
  }

  public getGroupsUser() {
    const groups = localStorage.getItem(USER_GROUPS);
    return JSON.parse(groups ?? '[]');
  }

  public savedRol(scopes: any) {
    localStorage.removeItem(LIST_SCOPES);
    localStorage.setItem(LIST_SCOPES, JSON.stringify(scopes));
  }

  public getRol() {
    const scopes = localStorage.getItem(LIST_SCOPES) ?? '[]';
    return JSON.parse(scopes);
  }

  public getToken(): string | null {
    return localStorage.getItem(TOKEN_KEY);
  }

  public getDataToGetNewToken(): any {
    return this.profileToken;
  }

  public setDataToGetNewToken(profileToken: any): any {
    this.profileToken = profileToken;
  }

  public savedResources(resources: any) {
    localStorage.removeItem(RESOURCES);
    localStorage.setItem(RESOURCES, JSON.stringify(resources));
  }

  public getListResources() {
    const resorces = localStorage.getItem(RESOURCES);
    return JSON.parse(resorces ?? '[]');
  }

  getCarantyHostQuienPuedeVerAquien(usuario: string, origen: OrigenMenu) {
    return this._http.get(
      `${environment.WebApiUrlCarantyamdev}/citas/permisos/detalle/${usuario}/${origen}?backend=azure`
    );
  }

  getByRolUser(origen: string, user: string) {
    return this._http.get(
      `${environment.WebApiUrlCarantyamdev}/menu/usuario?nombre_menu=${origen}&user_email=${user}&backend=azure`
    );
  }
}

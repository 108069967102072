import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SettingAppliedObject, SettingObject } from '../models/setting.model';
import { ResponseBackAzure } from '@core/models/responsehttp.model';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(protected _http: HttpClient) {}

  getSettings(filter?: {
    typeTransaction?: string;
    typeOperation?: string;
    id?: string;
    responsable?: string;
    userCreate?: string;
  }) {
    let httpParam = new HttpParams();
    httpParam = httpParam.append('typeTransaction', filter?.typeTransaction ?? '');
    httpParam = httpParam.append('userCreate', filter?.userCreate ?? '');
    httpParam = httpParam.append('typeOperation', filter?.typeOperation ?? '');
    httpParam = httpParam.append('id', filter?.id ?? '');
    httpParam = httpParam.append('responsable', filter?.responsable ?? '');

    return this._http.get<ResponseBackAzure>(
      `${environment.WebApiUrlCarantyamdev}/crm/proyeccion/configuracion?backend=azure`,
      {
        params: httpParam,
      }
    );
  }

  postSettings(setting: SettingObject) {
    return this._http.post<ResponseBackAzure>(
      `${environment.WebApiUrlCarantyamdev}/crm/proyeccion/configuracion?backend=azure`,
      setting
    );
  }

  putSettings(setting: SettingObject) {
    return this._http.put<ResponseBackAzure>(
      `${environment.WebApiUrlCarantyamdev}/crm/proyeccion/configuracion?backend=azure`,
      setting
    );
  }

  getSettingsApplied(id: string) {
    return this._http.get<ResponseBackAzure>(
      `${environment.WebApiUrlCarantyamdev}/crm/proyeccion/configuracion/aplicar/${id}?backend=azure`
    );
  }

  postSettingsApplied(setting: SettingAppliedObject) {
    return this._http.post<ResponseBackAzure>(
      `${environment.WebApiUrlCarantyamdev}/crm/proyeccion/configuracion/aplicar?backend=azure`,
      setting
    );
  }
}

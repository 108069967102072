import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  viewImage(imageUrl: string) {
    return Swal.fire({
      imageUrl,
      imageHeight: 1500,
      imageAlt: 'A tall image',
    });
  }

  async confirmAction(
    message: string,
    title?: string,
    icono?: string,
    showCancelButton?: boolean,
    confirmButtonText?: string,
    cancelButtonText?: string
  ) {
    return await Swal.fire({
      title: title ?? '',
      text: message ?? '',
      icon: (icono ?? 'info') as SweetAlertIcon,
      showCancelButton: showCancelButton ?? true,
      confirmButtonColor: '#70BE01',
      cancelButtonColor: '#5E5E5E',
      confirmButtonText: confirmButtonText ?? 'Aceptar',
      cancelButtonText: cancelButtonText ?? 'Cancelar',
      allowOutsideClick: false,
    });
  }

  success(message: string) {
    return Swal.fire({
      text: message,
      icon: 'success',
      allowOutsideClick: false,
      confirmButtonColor: '#70BE01',
    });
  }

  error(message: string) {
    return Swal.fire({
      text: message,
      icon: 'error',
      allowOutsideClick: false,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#70BE01',
    });
  }

  warning(message: string, title = '') {
    return Swal.fire({
      title: title,
      text: message,
      icon: 'warning',
      allowOutsideClick: false,
      confirmButtonColor: '#70BE01',
    });
  }

  info(message: string) {
    return Swal.fire({
      text: message,
      icon: 'info',
      allowOutsideClick: false,
      confirmButtonColor: '#70BE01',
    });
  }
}

import { NgModule } from '@angular/core';
import { MaterialModule } from './material-design/material.module';
import { CommonModule } from '@angular/common';
import { FactoryDialogComponent } from './components/factory-dialog/factory-dialog.component';
import { TableDataComponent } from './components/table-data/table-data.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataPropertyGetterPipe } from './components/table-data/pipe/data-property-getter.pipe';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { FormatDateCustomPipe } from './components/table-data/pipe/format-date-custom.pipe';

import { PaginationInternationalization } from './components/table-data/pagination.internationalization';
import { MatPaginatorIntl } from '@angular/material/paginator';

@NgModule({
  declarations: [FactoryDialogComponent, TableDataComponent, DataPropertyGetterPipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FormatDateCustomPipe,
    NgxMaskDirective,
    NgxMaskPipe,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [MaterialModule, TableDataComponent, ReactiveFormsModule, FormsModule, NgxMaskDirective, NgxMaskPipe],
  providers: [{ provide: MatPaginatorIntl, useClass: PaginationInternationalization }, provideNgxMask()],
})
export class SharedModule {}

import { AlertService } from './core/services/alert.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '@auth/auth.service';
import { StoreService } from '@auth/store.service';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus, EventMessage, EventType } from '@azure/msal-browser';
import { MenuItem } from '@core/models/roles.model';
import { ProfileType } from '@core/models/utils.model';
import { GraphService } from '@core/services/graph.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private graphService: GraphService,
    public _authService: AuthService,
    private _storeService: StoreService,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;

    if (this.loginDisplay) {
      this.graphService.getDataMeProfile().subscribe((profile: any) => {
        this.graphService.setProfileUser(profile);

        const profileToken = {
          uniqueId: profile.id,
          username: profile.userPrincipalName,
          name: profile.displayName,
          environment: 'login.app.web',
        };

        this._authService.loginApiToken(profileToken).subscribe((isLogin: boolean) => {
          if (!isLogin) {
            console.error('Error al iniciar sesión');
          }
        });

        this._storeService.getByRolUser('proyeccion', profile.userPrincipalName).subscribe((res: any) => {
          if (res.code === 200 && res.rol.length > 0) {
            res.rol.sort((a: any, b: any): number => {
              if (a.orden > b.orden) {
                return 1;
              }
              if (a.orden < b.orden) {
                return -1;
              }
              // a must be equal to b
              return 0;
            });

            //this.displayRingCentral = res.grupos.includes('RingCentral-CC');
            this._storeService.groupsRolSubject.next(res.grupos);
            this._storeService.menuSubject.next(res.rol);
            this._storeService.savedRol(res.rol.map((rol: MenuItem) => rol.routerLink));
          } else {
            this._alertService.info('Aún no cuentas con un rol asignado, contacta a tu administrador.');
          }
        });
      });
    }
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}

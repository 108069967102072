import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StoreService } from './store.service';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private _http: HttpClient,
    private _storageService: StoreService,
    private authServiceMS: MsalService
  ) {}

  loginApiToken(payload: any) {
    return this._http.post(`${environment.WebApiUrlCarantyamdev}/loginmovil?backend=azure-crm`, payload).pipe(
      map((response: any) => {
        if (response.code === 200) {
          this._storageService.saveToken(response.user[0].token);
          return true;
        }
        return false;
      })
    );
  }

  refreshToken() {
    console.log('refreshToken()');
    const payload = this._storageService.getDataToGetNewToken();

    return this._http.post(`${environment.WebApiUrlCarantyamdev}/loginmovil?backend=azure-crm`, payload).pipe(
      map((response: any) => {
        if (response.code === 200) {
          this._storageService.saveToken(response.user[0].token);
          return true;
        }
        return false;
      })
    );
  }
}

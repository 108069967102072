import { createAction, props } from '@ngrx/store';
import { SettingObject } from '../settings/models/setting.model';

export const loadInitSettings = createAction('[Settings API] Load init');

export const loadSuccessSettings = createAction(
  '[Settings API] Loaded All Success',
  props<{ settings: SettingObject[] }>()
);

export const loadErrorSettings = createAction('[Settings API] Loaded All Error', props<{ errorMessage: string }>());

export const resetStore = createAction('[RESET] STORE');

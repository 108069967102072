import { Injectable, inject } from '@angular/core';
import { AlertService } from '@core/services/alert.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, catchError, tap, concatMap } from 'rxjs/operators';
import { SettingsService } from '../settings/services/settings.service';
import { ProjectionsApiActions } from '.';
import { SettingObject } from '../settings/models/setting.model';

@Injectable()
export class ProjectionEffects {
  private _settingsService = inject(SettingsService);
  private _notificationsService = inject(AlertService);

  constructor(private actions$: Actions) {}

  SETTINGS_METHOD_GET$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectionsApiActions.loadInitSettings),
      concatMap(() => {
        return this._settingsService.getSettings().pipe(
          map((response) => {
            if (response.code === 200 && Array.isArray(response.result) && response.result.length > 0) {
              return ProjectionsApiActions.loadSuccessSettings({ settings: response.result as SettingObject[] });
            }

            return ProjectionsApiActions.loadErrorSettings({
              errorMessage: 'Error inesperado al cargar la configuración inicial, intente nuevamente.',
            });
          }),
          catchError(() => EMPTY)
        );
      })
    );
  });

  notifyApiError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProjectionsApiActions.loadErrorSettings),
        tap((action) => this._notificationsService.error(action.errorMessage))
      );
    },
    { dispatch: false }
  );
}

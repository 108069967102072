<header class="bg-gray-100">
  <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
    <div class="flex lg:flex-1">
      <a routerLink="/projection" class="-m-1.5 p-1.5">
        <span class="sr-only">Caranty</span>
        <img class="h-8 w-auto" src="../../../assets/img/i-c-logo-black.png" alt="" />
      </a>
    </div>
    <div class="flex lg:hidden">
      <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
        <span class="sr-only">Open main menu</span>
        <svg
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
      </button>
    </div>
    <div class="hidden lg:flex lg:gap-x-12">
      <button routerLink="/projection" class="text-base font-semibold leading-6 text-gray-900"></button>

      <div class="relative">
        <button
          type="button"
          class="flex items-center gap-x-1 text-base font-semibold leading-6 text-gray-900"
          aria-expanded="false"
          (click)="isOpenProjection = !isOpenProjection"
          type="button"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin">
          Proyección
          <mat-icon class="h-5 w-5 flex-none text-gray-400">
            {{ isOpenProjection ? 'expand_less' : 'expand_more' }}
          </mat-icon>
        </button>

        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="isOpenProjection"
          (overlayOutsideClick)="isOpenProjection = !isOpenProjection">
          <div
            class="absolute -left-20 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
            <div class="p-4">
              <ng-container *ngFor="let item of menuProjection">
                <div
                  class="group relative flex items-center gap-x-6 rounded-lg p-4 text-base leading-6 hover:bg-gray-50">
                  <div
                    class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                    <mat-icon class="group-hover:text-ica-primary">
                      {{ item.icon }}
                    </mat-icon>
                  </div>
                  <div class="flex-auto">
                    <button [routerLink]="item.routerLink" class="block font-semibold text-gray-900">
                      {{ item.label }}
                      <span class="absolute inset-0"></span>
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="grid hidden grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
              <a
                href="#"
                class="flex items-center justify-center gap-x-2.5 p-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-100">
                <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path
                    fill-rule="evenodd"
                    d="M2 10a8 8 0 1116 0 8 8 0 01-16 0zm6.39-2.908a.75.75 0 01.766.027l3.5 2.25a.75.75 0 010 1.262l-3.5 2.25A.75.75 0 018 12.25v-4.5a.75.75 0 01.39-.658z"
                    clip-rule="evenodd" />
                </svg>
                Watch demo
              </a>
              <a
                href="#"
                class="flex items-center justify-center gap-x-2.5 p-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-100">
                <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path
                    fill-rule="evenodd"
                    d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z"
                    clip-rule="evenodd" />
                </svg>
                Contact sales
              </a>
            </div>
          </div>
        </ng-template>
      </div>

      <div class="relative">
        <button
          type="button"
          class="flex items-center gap-x-1 text-base font-semibold leading-6 text-gray-900"
          aria-expanded="false"
          (click)="isOpenSettings = !isOpenSettings"
          type="button"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin">
          Configuración
          <mat-icon class="h-5 w-5 flex-none text-gray-400">
            {{ isOpenSettings ? 'expand_less' : 'expand_more' }}
          </mat-icon>
        </button>

        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="isOpenSettings"
          (overlayOutsideClick)="isOpenSettings = !isOpenSettings">
          <div
            class="absolute -left-20 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
            <div class="p-4">
              <ng-container *ngFor="let item of menuConfig">
                <div
                  class="group relative flex items-center gap-x-6 rounded-lg p-4 text-base leading-6 hover:bg-gray-50">
                  <div
                    class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                    <mat-icon class="group-hover:text-ica-primary">
                      {{ item.icon }}
                    </mat-icon>
                  </div>
                  <div class="flex-auto">
                    <button [routerLink]="item.routerLink" class="block font-semibold text-gray-900">
                      {{ item.label }}
                      <span class="absolute inset-0"></span>
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="grid hidden grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
              <a
                href="#"
                class="flex items-center justify-center gap-x-2.5 p-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-100">
                <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path
                    fill-rule="evenodd"
                    d="M2 10a8 8 0 1116 0 8 8 0 01-16 0zm6.39-2.908a.75.75 0 01.766.027l3.5 2.25a.75.75 0 010 1.262l-3.5 2.25A.75.75 0 018 12.25v-4.5a.75.75 0 01.39-.658z"
                    clip-rule="evenodd" />
                </svg>
                Watch demo
              </a>
              <a
                href="#"
                class="flex items-center justify-center gap-x-2.5 p-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-100">
                <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path
                    fill-rule="evenodd"
                    d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z"
                    clip-rule="evenodd" />
                </svg>
                Contact sales
              </a>
            </div>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="hidden lg:flex lg:flex-1 lg:justify-end">
      <button href="#" class="flex content-center text-base font-semibold leading-6 text-gray-900" (click)="logout()">
        Cerrar
        <mat-icon> logout </mat-icon>
      </button>
    </div>
  </nav>
</header>

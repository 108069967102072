import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { HomeComponent } from './home/home.component';
import { PageNotfoundComponent } from './page-notfound/page-notfound.component';
import { MsalGuard } from '@azure/msal-angular';
import { RolUserGuard } from './core/guards/rol-user.guard';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
        canActivate: [MsalGuard, RolUserGuard],
      },
      {
        path: 'projection',
        loadChildren: () => import('./projection/projection.module').then((m) => m.ProjectionModule),
        canActivate: [MsalGuard, RolUserGuard],
      },
      { path: '', redirectTo: 'projection', pathMatch: 'full' },
    ],
  },
  { path: '**', component: PageNotfoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

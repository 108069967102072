import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { StoreService } from '../../auth/store.service';
interface MenuObject {
  label: string;
  routerLink: string;
  icon: string;
}

@Component({
  selector: 'ic-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  isOpenSettings = false;
  isOpenProjection = false;

  menuConfig!: MenuObject[];

  menuProjection!: MenuObject[];

  constructor(
    private authService: MsalService,
    private _storeService: StoreService
  ) {}

  ngOnInit(): void {
    this._storeService.groupsRol$.subscribe((response) => {
      if (response.includes('Usuario Proyección')) {
        this.menuProjection = [
          {
            label: 'Agregar',
            routerLink: '/projection/add',
            icon: 'add_box',
          },
          {
            label: 'Mis proyecciones',
            routerLink: '/projection',
            icon: 'list',
          },
        ];
      }

      if (response.includes('Admin Proyección')) {
        this.menuProjection = [
          {
            label: 'Agregar',
            routerLink: '/projection/add',
            icon: 'add_box',
          },
          {
            label: 'Proyecciones',
            routerLink: '/projection/all',
            icon: 'list',
          },
        ];

        this.menuConfig = [
          {
            label: 'C2C',
            routerLink: '/settings/type/c2c',
            icon: 'settings',
          },
          {
            label: 'C2B',
            routerLink: '/settings/type/c2b',
            icon: 'settings',
          },
          {
            label: 'B2C',
            routerLink: '/settings/type/b2c',
            icon: 'settings',
          },
          {
            label: 'B2B',
            routerLink: '/settings/type/b2b',
            icon: 'settings',
          },
          {
            label: 'Colores',
            routerLink: '/settings/colors',
            icon: 'palette',
          },
        ];
      }
    });
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }
}

/* eslint-disable @ngrx/on-function-explicit-return-type */
import { createReducer, on } from '@ngrx/store';
import { ProjectionsApiActions } from '.';
import { ProjectionState } from '../app.state';

const initialProjectionState: ProjectionState = {
  settings: [],
  loadingSettings: false,
};

export const projectionReducer = createReducer(
  initialProjectionState,

  on(ProjectionsApiActions.loadInitSettings, (state) => {
    return { ...state, loadingSettings: true };
  }),

  on(ProjectionsApiActions.loadSuccessSettings, (state, action) => {
    return {
      ...state,
      ...action,
      loadingSettings: false,
    };
  })
);

import { ActionReducerMap } from '@ngrx/store';
import { SettingObject } from './settings/models/setting.model';
import { projectionReducer } from './state';

export interface ProjectionState {
  settings: SettingObject[];
  loadingSettings: boolean;
}

export interface AppState {
  projectionState: ProjectionState;
}

export const ROOT_REDUCERS: ActionReducerMap<AppState> = {
  projectionState: projectionReducer,
};

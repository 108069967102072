export const environment = {
  production: false,
  WebApiUrlCRM: ' https://carantyfunctioncrmdev.azurewebsites.net',
  MSKeyGeneral: 's92yM890f4aCI/wmtdOIKX4yCPXuaaclgfSJJtBpIDTMV2aTwgg3eQ==',
  MSKeyCRM: 'IeITEeTN7UQzHPbjTGoO139sWRnEKZCb5N7F36p4guUiwbvMt0mmaA==',

  webApiUrlDev: 'https://carantyfunctiondev.azurewebsites.net',

  WebApiUrlCarantyamdev: 'https://carantyamdev.azure-api.net/caranty-dev',
  OcpApimKey: '11c1c0d6d3b24f4888d23c9a01e9454d',

  msalConfig: {
    auth: {
      clientId: '7b770470-a45d-440a-8a61-147853a80c46',
      authority: 'https://login.microsoftonline.com/e0ee65fd-9f80-4118-99ca-6784aa65d6e1',
    },
    apiConfig: {
      graphEndpoint: 'https://graph.microsoft.com/v1.0',
      scopes: ['user.read'],
    },
  },
};
